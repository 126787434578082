.add-page {
    max-width: 500px;
    margin: 50px auto;
    padding: 30px;
    text-align: center;
    background-color: transparent;
    border-radius: 10px;
  }
  
  .add-page-title {
    margin-bottom: 30px;
    font-size: 36px;
  }
  
  .add-page-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .form-group {
    margin: 15px 0;
    text-align: left;
    width: 100%;
  }
  
  .form-label {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .form-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 2px 2px 5px #ccc;
  }
  
  .form-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    box-shadow: 2px 2px 5px #ccc;
  }