.loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loginForm div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
}

.loginForm label {
  font-weight: bold;
  margin-bottom: 5px;
}

.loginInput {
  padding: 5px;
  font-size: 16px;
  border: 1px solid gray;
  border-radius: 5px;
}

.loginForm button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: lightgray;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}