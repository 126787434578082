.container.testimonials__container {
    width: 40%;
    padding-bottom: 4rem;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    background: var(--color-primary);
}

.client__avatar {
    width: 4rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.3rem solid var(--color-primary-variant);
}

.testimonials {
    background: var(--color-bg-variant);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
}

.client__review {
    color: var(--color-light);
    font-weight: 300;
    display: block;
    width: 80%;
    margin: 0.8rem auto 0;
}

#testimonials {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.no__reviews {
    display: flex;
    justify-content: center;
    align-items: center;
}

.delete__btn {
    padding: 0.5rem 1rem;
    font-size: larger;
    background: transparent;
    color: red;
    position: relative;
}

/* ========== MEDIA QUERIES (MEDIUM DEVICES)  ========== */

@media screen and (max-width: 1024px) {
    .container.testimonials__container {
        width: 60%;
    }
}

/* ========== MEDIA QUERIES (SMALL DEVICES)  ========== */

@media screen and (max-width: 600px) {
    .container.testimonials__container {
        width: var(--container-width-sm);
    }

    .client__review {
        width: var(--container-width-sm);
    }
}